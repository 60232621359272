import { useEffect } from 'react';
import CardAuth from '.';
import { useGoogleLogin } from '@react-oauth/google';
import { useWeb3Modal } from '@web3modal/ethers5/react';
import axios from 'axios';

import { useWeb3Context } from 'src/context/web3';
import { useLoginSns, useLoginTelegram, useLoginWallet } from 'src/hooks';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import useWeb3Subscription from 'src/hooks/web3/useWeb3Subscription';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import ImageC from '../Image';
import Spinner from '../Spinner';

import classes from './index.module.less';

interface Props {
	onLoginWhatsappClick?: () => void;
	onLoginTelegramClick?: () => void;
	onLoginEmailClick?: () => void;
	onSignUpClick?: () => void;
	onSuccess?: () => void;
	loading?: boolean;
	updateLoadingState?: (val: boolean) => void;
}

const CardLogin = ({
	onLoginWhatsappClick,
	// onLoginTelegramClick,
	onLoginEmailClick,
	onSignUpClick,
	onSuccess,
	updateLoadingState,
	...props
}: Props) => {
	const { open } = useWeb3Modal();
	const { user, setUser } = useAuthReducer();
	const { provider, address } = useWeb3Context();
	const { web3CheckSubscription } = useWeb3Subscription();

	const { mutate: loginSns, isLoading: isLoadingLoginSns } = useLoginSns();
	const { mutate: loginTelegram, isLoading: isLoadingLoginTelegram } = useLoginTelegram();
	const { mutate: loginWallet, isLoading: isLoadingLoginWallet } = useLoginWallet();

	const isLoading =
		props.loading || isLoadingLoginSns || isLoadingLoginWallet || isLoadingLoginTelegram;

	const handleLoginWhatsappClick = () => {
		onLoginWhatsappClick?.();
	};

	const handleLoginTelegramClick = () => {
		// onLoginTelegramClick?.();

		updateLoadingState?.(true);

		(window as any)?.Telegram?.Login?.auth(
			{ bot_id: process.env.NEXT_PUBLIC_TELEGRAM_BOT_ID, request_access: true },
			(data: any) => {
				// console.log(data);

				if (!data) {
					// authorization failed
					notify('Telegram authorization cancelled', 'error');
					updateLoadingState?.(false);
					return;
				}

				loginTelegram(
					{ ...data },
					{
						onSuccess: async (res: any) => {
							if (res.status === 200 && res?.data?.data) {
								const subscription = await web3CheckSubscription();
								let result = { ...res.data.data };

								if (subscription) {
									result = { ...result, subscription };
								}

								setUser(result);

								notify('Successfully logged in');
								onSuccess?.();
								updateLoadingState?.(false);
							} else {
								// error wallet auth
								notify('Failed to login with telegram.', 'error');
								updateLoadingState?.(false);
							}
						},
					},
				);
			},
		);
	};

	const handleLoginEmailClick = () => {
		onLoginEmailClick?.();
	};

	const handleLoginWalletClick = () => {
		open();
	};

	const handleLoginGoogleClick = useGoogleLogin({
		onSuccess: (googleUserData: any) => {
			axios
				.get(
					`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUserData.access_token}`,
					{
						headers: {
							Authorization: `Bearer ${googleUserData.access_token}`,
							Accept: 'application/json',
						},
					},
				)
				.then((googleProfileData: any) => {
					loginSns(
						{
							sns_id: googleProfileData.data.id,
							sns_type: 'google',
							email: googleProfileData.data.email,
						},
						{
							onSuccess: (snsLoginRes: any) => {
								if (snsLoginRes.status === 200 && snsLoginRes?.data?.data) {
									setUser(snsLoginRes.data.data);
									notify('Successfully logged in');
									onSuccess?.();
								} else {
									notify(snsLoginRes?.data?.message ?? 'Failed to login', 'error');
								}
							},
						},
					);
				})
				.catch((/*err: any*/) => {
					// console.log(err)
				});
		},
		onError: (/*error: any*/) => {
			// console.log(error);
			notify('Failed to login with google', 'error');
		},
	});

	useEffect(() => {
		updateLoadingState?.(isLoading);
	}, [isLoading]);

	useEffect(() => {
		if (provider && address) {
			if (!user) {
				loginWallet(
					{ wallet_id: address },
					{
						onSuccess: async (res: any) => {
							if (res.status === 200 && res?.data?.data) {
								const subscription = await web3CheckSubscription();
								let result = { ...res.data.data };

								if (subscription) {
									result = { ...result, subscription };
								}

								setUser(result);

								notify('Successfully logged in');
								onSuccess?.();
							} else {
								// error wallet auth
								notify('Failed to authenticate wallet.', 'error');
							}
						},
					},
				);
			}
		}
	}, [provider]);

	return (
		<Spinner spinning={isLoading}>
			<CardAuth>
				<h1 className={classes.title}>Welcome</h1>
				<h2 className={classes.description}>Connect to Creo Play </h2>
				<div className={classes['login-options']}>
					<Button
						className={classes['login-opt-button']}
						onClick={() => handleLoginWhatsappClick()}
					>
						<ImageC alt="icon wallet" src="/icons/whatsapp.svg" height={20} width={20} />
						<span>Continue with Whatsapp</span>
					</Button>
					<Button
						className={classes['login-opt-button']}
						onClick={() => handleLoginTelegramClick()}
					>
						<ImageC alt="icon wallet" src="/icons/telegram.svg" height={20} width={20} />
						<span>Continue with Telegram</span>
					</Button>
					<Button className={classes['login-opt-button']} onClick={() => handleLoginWalletClick()}>
						<ImageC alt="icon wallet" src="/icons/wallet.svg" height={20} width={20} />
						<span>Continue with Wallet</span>
					</Button>
					<Button className={classes['login-opt-button']} onClick={() => handleLoginEmailClick()}>
						<ImageC alt="icon wallet" src="/icons/mail.svg" height={20} width={20} />
						<span>Continue with Email</span>
					</Button>
					<Button className={classes['login-opt-button']} onClick={() => handleLoginGoogleClick()}>
						<ImageC alt="icon google" src="/icons/google.svg" height={20} width={20} />
						<span>Continue with Google</span>
					</Button>
				</div>
				<div className={classes['create-account']}>
					<span>Don’t have an account yet?</span>
					<a onClick={() => onSignUpClick?.()}>Sign Up</a>
				</div>
				<div className={classes.agreement}>
					By signing up you are agreeing to <br />
					our <a>Terms of Use</a> and our <a>Privacy Policy</a>
				</div>
			</CardAuth>
		</Spinner>
	);
};

export default CardLogin;
