import { useDispatch, useSelector } from 'react-redux';

import { IXellarCurrentChain } from 'src/interface/Xellar';
import { IAuthState, setAuthUserData } from 'src/redux/slices/authSlice';
import { selectAuth } from 'src/redux/store';

export interface IUser {
	creo_id?: string;
	wallet_id?: string;
	google_id?: string;
	api_token?: string;
	subscription?: { endDate: string; isActive: boolean };
	profile?: {
		creo_id: string;
		profile_picture_url: string;
		wallet_id: string;
		google_id: string;
		google_email: string;
		fb_id: string;
		fb_email: string;
		username: string;
		email: string;
		hash_id: string;
		account_type: string;
		last_login_at: string;
		last_login_app: string;
		login_key: string;
		login_key_expired_at: string;
		is_account_completed: boolean;
		bio: string;
		level: number;
		current_experience: number;
		next_level_experience: number;
		nft_owned: number;
		volume_trade: number;
		booster_level: string;
		badges: {
			id: number;
			name: string;
			image_url: string;
			description: string;
		}[];
		is_premium_subscribed: boolean;
		premium_subscription_until: string;
		xellar_current_chain?: IXellarCurrentChain;
		wallet_type?: 'xellar' | 'external';
		recovery_key_viewed?: 0 | 1 | boolean;
	};
	xellar?: boolean;
}

const useAuthReducer = () => {
	const dispatch = useDispatch();
	const { user }: IAuthState = useSelector(selectAuth);

	const setUser = (data: IUser) => {
		let newUser: IUser = { ...data };
		if (newUser?.profile) {
			const profileData = newUser?.profile;
			if (newUser?.wallet_id !== newUser?.profile?.wallet_id) {
				newUser = {
					...newUser,
					wallet_id: profileData?.wallet_id,
					creo_id: profileData?.creo_id,
					google_id: profileData?.google_id,
					// xellar: ,
					// subscription:,
				};
			}
		}

		dispatch(
			setAuthUserData({
				user: newUser,
			}),
		);
	};

	const clearUser = () => {
		// console.log('clearUser');
		dispatch(
			setAuthUserData({
				user: null,
			}),
		);

		document.cookie = 'creo.persist=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	};

	const clearUserProfile = () => {
		let newUser: IUser = { ...user };
		delete newUser.profile;

		dispatch(
			setAuthUserData({
				user: newUser,
			}),
		);
	};

	return { user, setUser, clearUser, clearUserProfile };
};

export default useAuthReducer;
