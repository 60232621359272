import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	IAuthState,
	setModalAuthOpen,
	setModalExternalWalletOpen,
	setModalExternalWalletState,
	setSwitchWalletAddress,
} from 'src/redux/slices/authSlice';
import { selectAuth } from 'src/redux/store';

const useAuthAction = () => {
	const dispatch = useDispatch();
	const {
		user,
		modalAuthOpen,
		modalExternalWalletOpen,
		modalExternalWalletState,
		switchWalletAddress,
	}: IAuthState = useSelector(selectAuth);

	const [running, setRunning] = useState<boolean>(false);
	const actionRef: any = useRef(null);

	const closeModalAuth = () => {
		dispatch(setModalAuthOpen(false));
	};

	const callAuthAction = (action?: (...args: any[]) => void) => {
		actionRef.current = action ?? null;
		setRunning(true);
	};

	const triggerTargetAction = async () => {
		if (actionRef?.current) {
			await actionRef.current();
		}
		actionRef.current = null;
		setRunning(false);
	};

	const toggleModalExternalWallet = (open: boolean, state: 'add' | 'switch' | null) => {
		dispatch(setModalExternalWalletOpen(open));
		dispatch(setModalExternalWalletState(state));
	};

	const updateSwitchWalletAddress = (walletAddress: IAuthState['switchWalletAddress']) => {
		dispatch(setSwitchWalletAddress(walletAddress));
	};

	useEffect(() => {
		if (running) {
			if (!user?.api_token) {
				dispatch(setModalAuthOpen(true));
			} else {
				triggerTargetAction();
			}
		}
	}, [JSON.stringify(user), running]);

	useEffect(() => {
		if (!modalAuthOpen) {
			setRunning(false);
		}
	}, [modalAuthOpen]);

	return {
		modalAuthOpen,
		closeModalAuth,
		callAuthAction,
		modalExternalWalletOpen,
		modalExternalWalletState,
		switchWalletAddress,
		toggleModalExternalWallet,
		updateSwitchWalletAddress,
	};
};

export default useAuthAction;
