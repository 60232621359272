import classNames from 'classnames';

import ImageC from '../Image';

import classes from './index.module.less';

const CardAuth = ({
	className,
	style,
	showCreoplayImage = true,
	children,
}: {
	className?: string;
	style?: any;
	showCreoplayImage?: boolean;
	children: any;
}) => {
	return (
		<div className={classNames(classes.card, className)} style={style}>
			{showCreoplayImage && (
				<ImageC
					alt="logo creoplay"
					className={classes.logo}
					src="/icons/creoplay-with-text.svg"
					width={131}
					height={32}
				/>
			)}
			{children}
		</div>
	);
};

export default CardAuth;
