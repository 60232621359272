import classNames from 'classnames';

import classes from './index.module.less';

interface Props {
	children?: React.ReactNode;
	className?: string;
	spinning?: boolean;
	size?: number;
	width?: number;
	color?: string;
	style?: any;
}

const Spinner = ({
	children,
	className = '',
	spinning = false,
	size,
	width,
	color,
	style = {},
}: Props) => {
	return (
		<>
			{children ? (
				<div className={classNames(classes.container, className)} style={style}>
					{children}
					{spinning && (
						<>
							<div className={classes['loader-overlay']} />
							<span
								className={classes.loader}
								style={{ width: size, height: size, borderWidth: width, borderColor: color }}
							/>
						</>
					)}
				</div>
			) : (
				<span
					className={classNames(classes.loader, className)}
					style={{ width: size, height: size, borderWidth: width, borderColor: color, ...style }}
				/>
			)}
		</>
	);
};

export default Spinner;
