import { LoginButton } from '@telegram-auth/react';

import classes from './index.module.less';

const TelegramLoginWidget = () => {
	// Refs
	// const telegramWrapperRef = useRef<HTMLDivElement>(null);

	// useEffect(() => {
	// const scriptElement = document.createElement('script');
	// scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22';
	// scriptElement.setAttribute('data-telegram-login', 'creoplayapp_bot');
	// scriptElement.setAttribute('data-size', 'large');
	// scriptElement.setAttribute('data-auth-url', 'paste-redirect-url');
	// scriptElement.async = true;

	// telegramWrapperRef?.current?.appendChild(scriptElement);
	// }, []);

	return (
		<div className={classes['telegram-login-widget']}>
			<LoginButton
				botUsername={process.env.NEXT_PUBLIC_TELEGRAM_BOT_USERNAME ?? ''}
				// authCallbackUrl="/path/to/callback/url"
				onAuthCallback={(data) => {
					console.log(data);
					// call your backend here to validate the data and sign in the user
				}}
				buttonSize="large" // "large" | "medium" | "small"
				cornerRadius={1} // 0 - 20
				showAvatar={false} // true | false
				lang="en"
			/>
			{/* <div ref={telegramWrapperRef}></div> */}
		</div>
	);
};

export default TelegramLoginWidget;
