import { useEffect } from 'react';
import CardAuth from '.';
import classNames from 'classnames';

import { useWeb3Context } from 'src/context/web3';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { useGetRecoveryKey } from 'src/hooks/useWallet';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import ImageC from '../Image';
import Spinner from '../Spinner';

import classes from './index.module.less';

interface Props {
	onClose?: () => void;
}

const CardRecoveryKey = ({ onClose }: Props) => {
	const { copy } = useCopyToClipboard();

	const { address } = useWeb3Context();

	const { data, isLoading } = useGetRecoveryKey(address);

	let recoveryKey = data?.data?.data?.recovery_key;

	useEffect(() => {
		if (data?.data?.status && data?.data?.status !== 200) {
			notify(data?.data?.message, 'error');
			onClose?.();
		}
	}, [data?.data?.status]);

	return (
		<Spinner spinning={isLoading}>
			<CardAuth
				showCreoplayImage={false}
				style={{ height: 'fit-content', backgroundColor: '#1E1E1E', border: 'none' }}
			>
				<div className={classes.header}>
					<div></div>
					<div>
						<ImageC
							alt="icon close"
							className="cursor-pointer"
							src="/icons/icon-close-white.svg"
							width={16}
							height={16}
							onClick={onClose}
						/>
					</div>
				</div>

				<h1 className={classes.title}>Recovery Key</h1>

				<div className={classNames('w-100 d-flex flex-column align-items-center mt-2 pt-2')}>
					<ImageC src="/icons/icon-recovery-key.svg" width={88} height={88} />
					<span className="fs-12 fw-400 mt-4 mb-4 opacity-80">
						Please keep this recovery code below!
					</span>
					<div
						className={classNames(classes['recovery-key'], 'mt-3 mb-4')}
						style={{ minHeight: 158 }}
						onClick={() => copy(recoveryKey)}
					>
						{recoveryKey}
					</div>
					<Button
						className={classNames('w-100', classes['submit-btn'])}
						style={{ borderRadius: 49 }}
						onClick={() => copy(recoveryKey)}
						icon={
							<ImageC
								alt="icon copy"
								src={'/icons/icon-copy.svg'}
								className={classes['icon-copy']}
								width={16}
								height={16}
							/>
						}
					>
						Copy Recovery Key
					</Button>
				</div>
			</CardAuth>
		</Spinner>
	);
};

export default CardRecoveryKey;
