import { useEffect, useState } from 'react';
import Button from 'antd/lib/button';

import CardConnectEmail from '../CardAuth/CardConnectEmail';
import CardForgotPassword from '../CardAuth/CardForgotPassword';
import CardLogin from '../CardAuth/CardLogin';
import CardLoginEmail from '../CardAuth/CardLoginEmail';
import CardLoginTelegram from '../CardAuth/CardLoginTelegram';
import CardLoginWhatsapp from '../CardAuth/CardLoginWhatsapp';
import CardRecoverWhatsapp from '../CardAuth/CardRecoverWhatsapp';
import CardRecoveryKey from '../CardAuth/CardRecoveryKey';
import CardRegisterEmail from '../CardAuth/CardRegisterEmail';
import CardRegisterTelegram from '../CardAuth/CardRegisterTelegram';
import CardRegisterWhatsapp from '../CardAuth/CardRegisterWhatsapp';
import CardSignUp from '../CardAuth/CardSignUp';
import ImageC from '../Image';
import ModalC, { ModalCProps } from '../Modal';

import classes from './index.module.less';

interface Props extends ModalCProps {
	type?:
		| 'login'
		| 'signup'
		| 'register-email'
		| 'login-email'
		| 'forgot-password'
		| 'connect-email'
		| 'register-whatsapp'
		| 'login-whatsapp'
		| 'recover-whatsapp'
		| 'register-telegram'
		| 'login-telegram'
		| 'recover-telegram'
		| 'recovery-key';
	onClose?: (e?: any) => void;
	recoveryKey?: string;
}

const ModalAuth = ({ open, type: pType = 'login', ...props }: Props) => {
	const [type, setType] = useState<Props['type']>(pType);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (open) {
			setTimeout(() => {
				if (
					type === 'recover-whatsapp' ||
					type === 'login-whatsapp' ||
					type === 'register-whatsapp' ||
					type === 'login-telegram' ||
					type === 'register-telegram'
				) {
					document.querySelector(`.${classes.modal}`)?.querySelectorAll('input')?.[1]?.focus();
				} else {
					document.querySelector(`.${classes.modal}`)?.querySelectorAll('input')?.[0]?.focus();
				}
			}, 100);
		}
	}, [open, type]);

	const onClose = () => {
		if (pType !== 'recovery-key') {
			setType('login');
		}
		props.onClose?.();
	};

	const onHandleBackToSignUp = () => {
		if (type === 'register-email' || type === 'register-whatsapp' || type === 'register-telegram') {
			setType('signup');
		} else if (type === 'recover-whatsapp' || type === 'recover-telegram') {
			setType('register-whatsapp');
		} else {
			setType('login');
		}
	};

	return (
		<ModalC
			className={classes.modal}
			open={open}
			onClose={type === 'connect-email' ? props.onClose : onClose}
			centered
			showCloseIcon={false}
			closeOnOverlayClick={
				isLoading || type === 'register-email' || type === 'login-email' ? false : true
			}
		>
			<div className={classes['wrap-auth']}>
				{type === 'login' && (
					<CardLogin
						onLoginWhatsappClick={() => setType('login-whatsapp')}
						onLoginTelegramClick={() => setType('login-telegram')}
						onLoginEmailClick={() => setType('login-email')}
						onSignUpClick={() => setType('signup')}
						onSuccess={onClose}
						loading={isLoading}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'login-whatsapp' && (
					<CardLoginWhatsapp
						onSignUpClick={() => setType('recover-whatsapp')}
						onClose={() => setType('login')}
						onSuccess={onClose}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'login-telegram' && (
					<CardLoginTelegram
						onSignUpClick={() => setType('recover-telegram')}
						onClose={() => setType('login')}
						onSuccess={onClose}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'login-email' && (
					<CardLoginEmail
						onForgotPasswordClick={() => setType('forgot-password')}
						onClose={() => setType('login')}
						onSuccess={onClose}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'forgot-password' && (
					<CardForgotPassword onSuccess={onClose} updateLoadingState={setIsLoading} />
				)}
				{type === 'signup' && (
					<CardSignUp
						onSignupWhatsappClick={() => setType('register-whatsapp')}
						onSignupTelegramClick={() => setType('register-telegram')}
						onCreateAccountClick={() => setType('register-email')}
						onLoginClick={() => setType('login')}
						onSuccess={onClose}
						loading={isLoading}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'register-email' && (
					<CardRegisterEmail
						onRecoverClick={() => setType('login-email')}
						onClose={() => setType('signup')}
						onSuccess={onClose}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'register-whatsapp' && (
					<CardRegisterWhatsapp
						onRecoverClick={() => setType('login-whatsapp')}
						onClose={() => setType('signup')}
						onSuccess={onClose}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'register-telegram' && (
					<CardRegisterTelegram
						onRecoverClick={() => setType('login-telegram')}
						onClose={() => setType('signup')}
						onSuccess={onClose}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'recover-whatsapp' && (
					<CardRecoverWhatsapp
						onClose={() => setType('login-whatsapp')}
						onSuccess={onClose}
						updateLoadingState={setIsLoading}
					/>
				)}
				{type === 'connect-email' && (
					<CardConnectEmail
						onClose={props.onClose}
						onSuccess={props.onClose}
						updateLoadingState={setIsLoading}
					/>
				)}

				{type === 'recovery-key' && <CardRecoveryKey onClose={onClose} />}

				{type !== 'login-whatsapp' &&
					type !== 'recover-whatsapp' &&
					type !== 'register-whatsapp' &&
					type !== 'login-telegram' &&
					type !== 'recover-telegram' &&
					type !== 'register-telegram' &&
					type !== 'login-email' &&
					type !== 'register-email' &&
					type !== 'login' &&
					type !== 'recovery-key' &&
					!['connect-email'].includes(`${type || '-'}`) && (
						<Button
							className={classes['button-back']}
							icon={<ImageC src="/icons/left-white.svg" alt="icon back" width={16} />}
							shape="circle"
							onClick={onHandleBackToSignUp}
						/>
					)}
			</div>
		</ModalC>
	);
};

export default ModalAuth;
